import { enterpriseAuth, personalAuth, getUserAuthStatus } from '@/api/auth'

const auth = {
  state: {
    authInfo: {},
    authMessage: '',
  },

  mutations: {
    SET_MESSAGE: (state, message) => {
      state.authMessage = Number(message)
    },
    SET_AUTH_INFO: (state, info) => {
      state.authInfo = info
    },
  },
  actions: {
    // 添加企业认证
    EnterpriseAuth({ actions }, userInfo) {
      return new Promise((resolve, reject) => {
        enterpriseAuth(userInfo)
          .then((response) => {
            if (response.code == '200') {
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 添加个人认证
    PersonalAuth({ actions }, userInfo) {
      return new Promise((resolve, reject) => {
        personalAuth(userInfo)
          .then((response) => {
            if (response.code == '200') {
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 查询用户认证状态
    GetUserAuthStatus({ commit }, userId) {
      return new Promise((resolve, reject) => {
        getUserAuthStatus(userId)
          .then((response) => {
            // console.log('认证状态',response)
            if (response.code == '200') {
              commit('SET_MESSAGE', response.message)
              response.result
                ? commit('SET_AUTH_INFO', response.result)
                : commit('SET_AUTH_INFO', {})
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default auth
