import Vue from 'vue'
import { USER_INFO, ROLE_INFO } from '@/store/mutation-types'
const getters = {
  token: (state) => state.user.token,
  userInfo: (state) => {
    state.user.info = Vue.ls.get(USER_INFO)
    return state.user.info
  },
  authMessage: (state) => state.auth.authMessage,
  authInfo: (state) => state.auth.authInfo,
  role: (state) => {
    state.user.role = Vue.ls.get(ROLE_INFO)
    return state.user.role
  },
}

export default getters
