<template>
  <a-locale-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-locale-provider>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
  data() {
    return {
      locale: zhCN,
    }
  },
  mounted() {},
  watch: {
    $route: function () {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
  },
  created() {
    // this.setColor()
  },
  methods: {
    setColor() {
      // document.documentElement.style.setProperty('--theme-color', 'yellow')
    },
  },
}
</script>

<style lang="less">
p {
  margin: 0;
}
:root {
  --theme-color: pink;
}
</style>
