<template>
  <div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive">
        <!-- 这里是会被缓存的视图组件，比如 Home！ -->
      </router-view>
    </keep-alive>

    <router-view v-if="!$route.meta.keepAlive">
      <!-- 这里是不会被缓存的视图组件，比如 Profile！ -->
    </router-view>
  </div>
</template>

<script>
export default {
  name: 'BlankLayout',
}
</script>

<style scoped></style>
