import Vue from 'vue'
import login from './login.vue' // 引入登录组件

const LoginDialog = Vue.extend(login)

login.install = function (data) {
  let instance = new LoginDialog({
    data,
  }).$mount()

  document.body.appendChild(instance.$el)

  Vue.nextTick(() => {
    instance.showModal()
  })
}

export default login // 导出
