import Vue from 'vue'
import changePassword from './ChangePassword'

const PasswordDialog = Vue.extend(changePassword)

changePassword.install = function (data) {
  let instance = new PasswordDialog({
    data,
  }).$mount()

  document.body.appendChild(instance.$el)

  Vue.nextTick(() => {
    instance.showModal(data.username)
  })
}

export default changePassword // 导出
