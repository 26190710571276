import {
  getAction,
  deleteAction,
  putAction,
  postAction,
  httpAction,
} from '@/api/manage'
import Vue from 'vue'
import { UI_CACHE_DB_DICT_DATA } from '@/store/mutation-types'

// 角色管理
const addRole = (params) => postAction('/sys/role/add', params)
const editRole = (params) => putAction('/sys/role/edit', params)
const checkRoleCode = (params) => getAction('/sys/role/checkRoleCode', params)
const queryall = (params) => getAction('/sys/role/queryall', params)

// 用户管理
const addUser = (params) => postAction('/sys/user/add', params)
const editUser = (params) => putAction('/sys/user/edit', params)
const queryUserRole = (params) => getAction('/sys/user/queryUserRole', params)
const getUserList = (params) => getAction('/sys/user/list', params)
const frozenBatch = (params) => putAction('/sys/user/frozenBatch', params)
// 验证用户是否存在
const checkOnlyUser = (params) => getAction('/sys/user/checkOnlyUser', params)
// 改变密码
const changePassword = (params) => putAction('/sys/user/changePassword', params)

// 权限管理
const addPermission = (params) => postAction('/sys/permission/add', params)
const editPermission = (params) => putAction('/sys/permission/edit', params)
const getPermissionList = (params) => getAction('/sys/permission/list', params)
/* update_begin author:wuxianquan date:20190908 for:添加查询一级菜单和子菜单查询api */
const getSystemMenuList = (params) =>
  getAction('/sys/permission/getSystemMenuList', params)
const getSystemSubmenu = (params) =>
  getAction('/sys/permission/getSystemSubmenu', params)
const getSystemSubmenuBatch = (params) =>
  getAction('/sys/permission/getSystemSubmenuBatch', params)

const queryTreeList = (params) =>
  getAction('/sys/permission/queryTreeList', params)
const queryTreeListForRole = (params) =>
  getAction('/sys/role/queryTreeList', params)
const queryListAsync = (params) =>
  getAction('/sys/permission/queryListAsync', params)
const queryRolePermission = (params) =>
  getAction('/sys/permission/queryRolePermission', params)
const saveRolePermission = (params) =>
  postAction('/sys/permission/saveRolePermission', params)
const queryPermissionsByUser = (params) =>
  getAction('/sys/permission/getUserPermissionByToken', params)
const loadAllRoleIds = (params) =>
  getAction('/sys/permission/loadAllRoleIds', params)
const getPermissionRuleList = (params) =>
  getAction('/sys/permission/getPermRuleListByPermId', params)
const queryPermissionRule = (params) =>
  getAction('/sys/permission/queryPermissionRule', params)

// 部门管理
const queryDepartTreeList = (params) =>
  getAction('/sys/sysDepart/queryTreeList', params)
const queryIdTree = (params) => getAction('/sys/sysDepart/queryIdTree', params)
const queryParentName = (params) =>
  getAction('/sys/sysDepart/queryParentName', params)
const searchByKeywords = (params) =>
  getAction('/sys/sysDepart/searchBy', params)
const deleteByDepartId = (params) =>
  deleteAction('/sys/sysDepart/delete', params)

// 二级部门管理
const queryDepartPermission = (params) =>
  getAction('/sys/permission/queryDepartPermission', params)
const saveDepartPermission = (params) =>
  postAction('/sys/permission/saveDepartPermission', params)
const queryTreeListForDeptRole = (params) =>
  getAction('/sys/sysDepartPermission/queryTreeListForDeptRole', params)
const queryDeptRolePermission = (params) =>
  getAction('/sys/sysDepartPermission/queryDeptRolePermission', params)
const saveDeptRolePermission = (params) =>
  postAction('/sys/sysDepartPermission/saveDeptRolePermission', params)
const queryMyDepartTreeList = (params) =>
  getAction('/sys/sysDepart/queryMyDeptTreeList', params)

// 日志管理
// const getLogList = (params)=>getAction("/sys/log/list",params);
const deleteLog = (params) => deleteAction('/sys/log/delete', params)
const deleteLogList = (params) => deleteAction('/sys/log/deleteBatch', params)

// 数据字典
const addDict = (params) => postAction('/sys/dict/add', params)
const editDict = (params) => putAction('/sys/dict/edit', params)
const treeList = (params) => getAction('/sys/dict/treeList', params)
const addDictItem = (params) => postAction('/sys/dictItem/add', params)
const editDictItem = (params) => putAction('/sys/dictItem/edit', params)

// 字典标签专用（通过code获取字典数组）
export const ajaxGetDictItems = (code, params) =>
  getAction(`/sys/dict/getDictItems/${code}`, params)
// 从缓存中获取字典配置
function getDictItemsFromCache(dictCode) {
  if (
    Vue.ls.get(UI_CACHE_DB_DICT_DATA) &&
    Vue.ls.get(UI_CACHE_DB_DICT_DATA)[dictCode]
  ) {
    const dictItems = Vue.ls.get(UI_CACHE_DB_DICT_DATA)[dictCode]
    return dictItems
  }
}

// 系统通告
const doReleaseData = (params) =>
  getAction('/sys/annountCement/doReleaseData', params)
const doReovkeData = (params) =>
  getAction('/sys/annountCement/doReovkeData', params)
// 获取系统访问量
const getLoginfo = (params) => getAction('/sys/loginfo', params)
const getVisitInfo = (params) => getAction('/sys/visitInfo', params)

// 根据部门主键查询用户信息
const queryUserByDepId = (params) =>
  getAction('/sys/user/queryUserByDepId', params)

// 重复校验
const duplicateCheck = (params) => getAction('/sys/duplicate/check', params)
// 加载分类字典
const loadCategoryData = (params) =>
  getAction('/sys/category/loadAllData', params)
const checkRuleByCode = (params) =>
  getAction('/sys/checkRule/checkByCode', params)
// 加载我的通告信息
const getUserNoticeInfo = (params) =>
  getAction('/sys/sysAnnouncementSend/getMyAnnouncementSend', params)
const getTransitURL = (url) =>
  `/sys/common/transitRESTful?url=${encodeURIComponent(url)}`

// 获取编码规则
const getSn = (sn, params) =>
  postAction('sys/duplicate/getSn/' + encodeURIComponent(sn), params)

// 获取发布信息类型
const dictItemList = (params) =>
  getAction('/sys/dictItem/list/yqms_inforeleasetype', params)
// 获取全部角色（无分页）
const roleQueryall = (params) => getAction('/sys/role/queryall', params)
// 获取所有资讯类型
const yqmsInformationTypeListAll = (params) =>
  getAction('/yuanqu/yqmsInformationType/listAll', params)

// 企业信息-查询所有审核通过的
const yqmsEnterpriseListAll = (params) =>
  getAction('/yuanqu/yqmsEnterprise/listAll', params)

const activitiListAll = (params) =>
  getAction('/activiti/models/listAll', params)

const getMyCreateTaskByPage = (params) =>
  getAction('/activiti/task/getMyCreateTaskByPage', params)

const yqmsEnterpriseInfo = (params) =>
  getAction('/yuanqu/yqmsEnterprise/info', params)

// //企业认证-编辑
const yqmsEnterpriseEdit = (params) =>
  putAction('/yuanqu/yqmsEnterprise/edit', params)

//个人认证-通过当前登录用户查询信息
const yqmsPersonexamInfo = (params) =>
  getAction('/yuanqu/yqmsPersonexam/info', params)

//个人认证-编辑
const yqmsPersonexamEdit = (params) =>
  putAction('/yuanqu/yqmsPersonexam/edit', params)

//根据用户id查询用户信息
const userQueryById = (params) => getAction('/sys/user/queryById', params)

//活动表-分页列表查询
const yqmsActivityList = (params) =>
  getAction('/yqms/yqmsActivity/list', params)

//活动表-通过id查询
const yqmsActivityQueryById = (params) =>
  getAction('/yqms/yqmsActivity/queryById', params)

//活动表-通过id查询
const yqmsActivityApply = (params) =>
  postAction(`/yqms/yqmsActivity/apply?id=${params}`)

//活动表-分页列表查询我的活动
const yqmsActivityMyApplyPage = (params) =>
  getAction('/yqms/yqmsActivity/myApplyPage', params)

//公开的招聘信息
const getRecruitList = (params) =>
  getAction('/publicInfo/getRecruitList', params)

//字典管理-获取全部字典数据
const dictQueryAllDictItems = (params) =>
  getAction('/sys/dict/queryAllDictItems', params)

//首页获取信息
const publicInfoIndex = (params) => getAction('/publicInfo/index/1')

//资讯信息-分页列表查询
const yqmsInformationList = (params) =>
  getAction('/yuanqu/yqmsInformation/list', params)

//机构管理-分页列表查询
const yqmsAgencyList = (params) => getAction('/yuanqu/yqmsAgency/list', params)

//企业信息-分页列表查询
const yqmsEnterpriseList = (params) =>
  getAction('/yuanqu/yqmsEnterprise/list', params)

// 会议室申请-查询当天已预约时间段
const getUsedTimesByDate = (params) =>
  getAction(
    `/yuanqu/yqmsMeetingroomApplyfor/getUsedTimesByDate/${params.id}/${params.date}`
  )

// 查询申请会议室表单id
const queryByTableNames = (params) =>
  getAction('/online/cgform/head/queryByTableNames', params)

// 会议室-无分页列表查询
const yqmsMeetRoomListNotPage = (params) =>
  getAction('/yuanqu/yqmsMeetRoom/listNotPage', params)

// 资讯信息-通过id查询
const yqmsInformationQueryById = (params) =>
  getAction('/yuanqu/yqmsInformation/queryById', params)

// 统计接口-获取PC统计数据
const statisticalPcCount = (params) =>
  getAction('/yuanqu/statistical/pcCount', params)

// 根据流程模型id查流程信息
const getModelById = (modelId) =>
  getAction(`/activiti/models/getModelById/${modelId}`)

// 招聘信息-分页列表查询(根据企业查)
const yqmsRecruitlListByEnterpriseId = (params) =>
  getAction('/yuanqu/yqmsRecruit/listByEnterpriseId', params)

// 消息通知-已读
const editByAnntIdAndUserId = (params) =>
  putAction('sys/sysAnnouncementSend/editByAnntIdAndUserId', params)

// 在线留言
const yqmsMessageAdd = (params) =>
  postAction('/yuanqu/yqms_Message/add', params)

// 根据流程id获取流程绑定的角色ids
const getRoleListByModelId = (params) =>
  getAction(`/activiti/models/getRoleListByModelId/${params}`)

//修改用户信息（不修改角色和部门）
const editUserInfo = (params) => putAction('/sys/user/editInfo', params)

//公告-通过id查询
const yqmsNoticeQueryById = (params) =>
  getAction('/yuanqu/yqmsNotice/queryById', params)

//修改密码
const updatePassword = (params) => putAction('/sys/user/updatePassword', params)

//获取流程id
const getFormDataByTaskId = (params) =>
  getAction(`/activiti/hiFormData/getFormDataByTaskId/${params}`)

// 会议室-通过id查询
const yqmsMeetRoomQueryById = (params) =>
  getAction('/yuanqu/yqmsMeetRoom/queryById', params)

// 中转HTTP请求
export const transitRESTful = {
  get: (url, parameter) => getAction(getTransitURL(url), parameter),
  post: (url, parameter) => postAction(getTransitURL(url), parameter),
  put: (url, parameter) => putAction(getTransitURL(url), parameter),
  http: (url, parameter) => httpAction(getTransitURL(url), parameter),
}

export {
  addRole,
  editRole,
  checkRoleCode,
  addUser,
  editUser,
  queryUserRole,
  getUserList,
  queryall,
  frozenBatch,
  checkOnlyUser,
  changePassword,
  getPermissionList,
  addPermission,
  editPermission,
  queryTreeList,
  queryListAsync,
  queryRolePermission,
  saveRolePermission,
  queryPermissionsByUser,
  loadAllRoleIds,
  getPermissionRuleList,
  queryPermissionRule,
  queryDepartTreeList,
  queryIdTree,
  queryParentName,
  searchByKeywords,
  deleteByDepartId,
  deleteLog,
  deleteLogList,
  addDict,
  editDict,
  treeList,
  addDictItem,
  editDictItem,
  doReleaseData,
  doReovkeData,
  getLoginfo,
  getVisitInfo,
  queryUserByDepId,
  duplicateCheck,
  queryTreeListForRole,
  getSystemMenuList,
  getSystemSubmenu,
  getSystemSubmenuBatch,
  loadCategoryData,
  checkRuleByCode,
  queryDepartPermission,
  saveDepartPermission,
  queryTreeListForDeptRole,
  queryDeptRolePermission,
  saveDeptRolePermission,
  queryMyDepartTreeList,
  getUserNoticeInfo,
  getDictItemsFromCache,
  getSn,
  dictItemList,
  roleQueryall,
  yqmsInformationTypeListAll,
  yqmsEnterpriseListAll,
  activitiListAll,
  getMyCreateTaskByPage,
  yqmsEnterpriseInfo,
  yqmsEnterpriseEdit,
  yqmsPersonexamInfo,
  yqmsPersonexamEdit,
  userQueryById,
  yqmsActivityList,
  yqmsActivityQueryById,
  yqmsActivityApply,
  yqmsActivityMyApplyPage,
  getRecruitList,
  dictQueryAllDictItems,
  publicInfoIndex,
  yqmsInformationList,
  yqmsAgencyList,
  yqmsEnterpriseList,
  getUsedTimesByDate,
  queryByTableNames,
  yqmsMeetRoomListNotPage,
  yqmsInformationQueryById,
  statisticalPcCount,
  getModelById,
  yqmsRecruitlListByEnterpriseId,
  editByAnntIdAndUserId,
  yqmsMessageAdd,
  getRoleListByModelId,
  editUserInfo,
  yqmsNoticeQueryById,
  updatePassword,
  getFormDataByTaskId,
  yqmsMeetRoomQueryById,
}
