import { axios } from '@/utils/request'

/**
 * @param parameter
 * @returns {*}
 */
export function enterpriseAuth(parameter) {
  return axios({
    url: '/yuanqu/yqmsEnterprise/add',
    method: 'post',
    data: parameter,
  })
}

export function personalAuth(parameter) {
  return axios({
    url: '/yuanqu/yqmsPersonexam/add',
    method: 'post',
    data: parameter,
  })
}

export function getUserAuthStatus(parameter) {
  return axios({
    url: `/yuanqu/auth/getUserAuthStatus/${parameter}`,
    method: 'get',
  })
}

export function yqmsEnterpriseListAll() {
  return axios({
    url: '/yuanqu/yqmsEnterprise/listAll',
    method: 'get',
  })
}
