<template>
  <div>
    <a-modal
      v-model="visible"
      :footer="null"
      centered
      dialogClass="login-style"
      :maskClosable="false"
      :width="400"
    >
      <div>
        <div class="title flex-wrap flex-h-center">
          <img src="@/assets/logo.png" alt="" width="80" height="80" />
          <div>注册平台账户</div>
        </div>
        <div class="content">
          <a-form-model
            layout="vertical"
            hideRequiredMark
            ref="formData"
            :rules="rules"
            :model="formData"
          >
            <a-form-model-item label="用户名" prop="username">
              <a-input
                v-model="formData.username"
                placeholder="请设置用户名"
                autocomplete="off"
                :maxLength="20"
              >
              </a-input>
            </a-form-model-item>

            <a-form-model-item label="密码" prop="password">
              <a-input-password
                v-model="formData.password"
                placeholder="请设置登录密码"
                autocomplete="new-password"
                :maxLength="18"
              />
            </a-form-model-item>

            <a-form-model-item label="手机号" prop="mobile">
              <a-input
                v-model="formData.mobile"
                placeholder="请输入手机号"
                :maxLength="11"
              >
              </a-input>
            </a-form-model-item>

            <a-row>
              <a-col class="gutter-row" :span="16">
                <a-form-model-item label="验证码" prop="captcha">
                  <a-input
                    v-model="formData.captcha"
                    type="text"
                    placeholder="请输入验证码"
                    class="code-input"
                    :maxLength="6"
                  >
                  </a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-button
                  type="primary"
                  class="code-btn"
                  :disabled="state.smsSendBtn"
                  @click.stop.prevent="getCaptcha"
                  v-text="
                    (!state.smsSendBtn && '获取验证码') || state.time + ' s'
                  "
                ></a-button>
              </a-col>
            </a-row>

            <a-form-model-item prop="agreement">
              <div class="agreement">
                <a-checkbox @change="changeCheck"> </a-checkbox>
                <span
                  >我已阅读并同意<em @click="openAgreementModal"
                    >《注册协议》</em
                  ></span
                >
              </div>
            </a-form-model-item>
            <a-form-model-item>
              <a-button type="primary" class="register-btn" @click="submitForm">
                免费注册
              </a-button>
            </a-form-model-item>
            <a-form-model-item>
              <div class="reg-tips">
                <span>已有账号？</span
                ><span @click="openLoginDialog">登录</span>
              </div>
            </a-form-model-item>
          </a-form-model>
        </div>
        <agreement-modal ref="agreementModal" />
      </div>
    </a-modal>
  </div>
</template>
<script>
import reg from '@/api/regular'
import { postAction } from '@/api/manage'
import AgreementModal from './AgreementModal'
import { duplicateCheck } from '@/api/api'
export default {
  components: {
    AgreementModal,
  },
  data() {
    let validateAgreement = (rule, value, callback) => {
      if (!this.checked) {
        callback(new Error('请同意注册协议'))
      } else {
        callback()
      }
    }

    let validateName = (rule, value, callback) => {
      this.validateUsername(rule, value, callback)
    }
    return {
      visible: false,
      formData: {
        username: '',
        password: '',
        mobile: '',
        captcha: '',
      },
      rules: {
        username: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'change',
          },
          { validator: validateName, trigger: 'change' }
        ],
        password: [
          {
            required: true,
            message: '请输入登录密码',
            trigger: 'change',
          },
          { min: 6, max: 18, message: '登录密码为6~18位', trigger: 'change' },
        ],
        mobile: [
          {
            required: true,
            message: '请输入手机号码',
            trigger: 'change',
          },
          {
            required: true,
            pattern: reg.phone,
            message: '请输入正确的手机号码',
            trigger: 'change',
          },
        ],
        captcha: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'change',
          },
          {
            required: true,
            pattern: reg.captcha,
            message: '请输入正确的验证码',
            trigger: 'change',
          },
        ],
        agreement: [{ validator: validateAgreement, trigger: 'change' }],
      },
      state: {
        time: 60,
        smsSendBtn: false,
      },
      checked: false,
    }
  },
  methods: {
    showModal() {
      this.visible = true
    },
    changeCheck(e) {
      this.checked = e.target.checked
    },
    // 打开登录弹框
    openLoginDialog() {
      this.visible = false
      this.$login()
    },
    // 注册
    submitForm() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          const params = {
            username: this.formData.username,
            password: this.formData.password,
            phone: this.formData.mobile,
            smscode: this.formData.captcha,
          }
          postAction('/sys/user/register', params).then((res) => {
            if (res.success) {
              this.$message.success(res.message)
              this.openLoginDialog()
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          return false
        }
      })
    },
    // 获取验证码
    getCaptcha() {
      let that = this
      this.$refs.formData.validateField(['mobile'], (errMsg) => {
        if (errMsg === '') {
          this.state.smsSendBtn = true
          let interval = window.setInterval(() => {
            if (that.state.time-- <= 0) {
              that.state.time = 60
              that.state.smsSendBtn = false
              window.clearInterval(interval)
            }
          }, 1000)
          const params = {
            mobile: this.formData.mobile,
            smsmode: '1',
          }
          postAction('/sys/sms', params)
            .then((res) => {
              if (!res.success) {
                this.$message.error(res.message)
              }
            })
            .catch((err) => {
              clearInterval(interval)
              that.state.time = 60
              that.state.smsSendBtn = false
              this.requestFailed(err)
            })
        }
      })
    },

    requestFailed(err) {
      this.$message.error(
        ((err.response || {}).data || {}).message || '请求出现错误，请稍后再试'
      )
    },

    openAgreementModal() {
      this.$refs.agreementModal.showModal()
    },
    
    validateUsername(rule, value, callback) {
      console.log(value)
      let params = {
        tableName: 'sys_user',
        fieldName: 'username',
        fieldVal: value,
      }
      duplicateCheck(params).then((res) => {
        if (res.success) {
          callback()
        } else {
          callback('用户名已存在!')
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .login-style {
  border-radius: 30px;

  .ant-modal-content {
    border-radius: 10px;

    .title {
      margin-bottom: 30px;
      > div {
        font-size: 30px;
        font-weight: bold;
        color: #333333;
        margin-left: 20px;
      }
    }
  }

  .content {
    .forget {
      font-size: 14px;
      color: #1285d6;
      text-align: right;
    }

    .register-btn {
      width: 100%;
      height: 40px;
      font-size: 16px;
    }

    .reg-tips {
      text-align: center;
      span {
        font-size: 14px;
        color: #333333;
        &:nth-child(2) {
          color: #1285d6;
          cursor: pointer;
        }
      }
    }

    .ant-form {
      .ant-form-item {
        margin-bottom: 10px;
      }
      .ant-input {
        height: 40px;
        border-radius: 5px;
      }

      .code-input {
        border-right: none;
        border-radius: 5px 0 0 5px;
      }

      .gutter-row {
        .ant-form-item {
          margin-bottom: 5px;
        }
      }
    }
  }

  .agreement {
    > span {
      margin-left: 10px;

      em {
        font-style: normal;
        color: #1285d6;
        cursor: pointer;
      }
    }
  }

  .code-btn {
    border: none;
    width: 100%;
    height: 40px;
    border-radius: 0 5px 5px 0;
    margin-top: 29px;

    &[disabled] {
      background: #40a9ff;
      color: #fff;
    }
  }
}
</style>
