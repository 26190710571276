<template>
  <div>
    <footer>
      <div class="flex-wrap flex-h-center flex-between">
        <div class="website-info">
          <!-- <ul class="relevant flex-wrap flex-h-center">
            <li>关于我们</li>
            <li class="v-line"></li>
            <li>联系我们</li>
          </ul> -->
          <p>版权所有 咸阳高新创新科技孵化器有限公司</p>
          <p @click="publicSecurity('https://www.beian.gov.cn/')">
            <img src="@/assets/ghs.png" alt="" width="20" height="20" />
            <span style="margin-left: 5px"
              >陕公网安备 61040202000600号</span
            >
          </p>
          <p @click="record('https://beian.miit.gov.cn/')">陕ICP备2021015502号-1 监督举报电话： 029-32003813</p>
          <p>地址：咸阳市高科三路创业大厦</p>
          <p>招商热线：029-32003666</p>
        </div>
        <div class="qr-code">
          <ul class="flex-wrap">
            <li>
              <div></div>
              <div>移动端</div>
            </li>
            <!-- <li>
              <div></div>
              <div>PC端</div>
            </li> -->
          </ul>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  components: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods:{
    publicSecurity(url){
      window.open(url,'_blank')
    },
    record(url){
      window.open(url,'_blank')
    }
  },
}
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  font-family: Source Han Sans CN;

  li {
    list-style: none;
  }
}

footer {
  height: 291px;
  background: #000000;

  > div:nth-child(1) {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    color: #ffffff;

    .website-info {
      font-size: 16px;
      p {
        margin-bottom: 8px;
      }
      .relevant {
        font-size: 16px;
        margin-bottom: 45px;

        .v-line {
          width: 2px;
          height: 21px;
          background: #ffffff;
          margin: 0 12px;
        }
      }
    }

    .qr-code {
      li {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        &:nth-child(1) {
          > div:nth-child(1) {
            width: 136px;
            height: 136px;
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.46);
            border-radius: 5px;
            background-image: url(~@/assets/ewm.jpg);
            background-size: 136px 136px;
            margin-bottom: 19px;
          }
        }

        &:nth-child(2) {
          margin-left: 98px;
          > div:nth-child(1) {
            width: 136px;
            height: 136px;
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.46);
            border-radius: 5px;
            background-image: url(~@/assets/logo.png);
            margin-bottom: 19px;
          }
        }
      }
    }
  }
}
</style>
