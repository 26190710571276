<template>
  <div>
    <a-modal
      v-model="visible"
      :footer="null"
      centered
      dialogClass="login-style"
      :maskClosable="false"
      :width="400"
    >
      <div>
        <div class="title flex-wrap flex-h-center">
          <img src="@/assets/logo.png" alt="" width="80" height="80" />
          <div>登录平台门户</div>
        </div>
        <div class="content">
          <a-tabs default-active-key="1" @change="changeTabs">
            <a-tab-pane key="1" tab="账号登录">
              <a-form-model
                ref="accountNumberForm"
                :rules="rules"
                :model="formData"
              >
                <a-form-model-item prop="username">
                  <a-input
                    v-model="formData.username"
                    placeholder="请输入用户名"
                  >
                  </a-input>
                </a-form-model-item>
                <a-form-model-item prop="password">
                  <a-input
                    v-model="formData.password"
                    type="password"
                    placeholder="输入密码"
                  >
                  </a-input>
                </a-form-model-item>

                <a-row class="input-code" v-if="showCaptcha == 1">
                  <a-col class="gutter-row" :span="16">
                    <a-form-model-item prop="inputCode">
                      <a-input
                        v-model="formData.inputCode"
                        :maxLength="4"
                        type="text"
                        placeholder="请输入验证码"
                        class="code-input"
                      >
                      </a-input>
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="8" class="code-img">
                    <img
                      v-if="requestCodeSuccess"
                      :src="randCodeImage"
                      @click="handleChangeCheckCode"
                    />
                    <img
                      v-else
                      src="../../assets/checkcode.png"
                      @click="handleChangeCheckCode"
                    />
                  </a-col>
                </a-row>

                <a-form-model-item>
                  <div class="forget flex-wrap flex-justify-content-end">
                    <div @click="openPassword">忘记密码？</div>
                  </div>
                </a-form-model-item>
                <a-form-model-item>
                  <a-button
                    type="primary"
                    class="login-btn"
                    @click="submitForm"
                  >
                    登录
                  </a-button>
                </a-form-model-item>
                <a-form-model-item>
                  <div class="reg-tips">
                    <span>没有账号？</span
                    ><span @click="openRegisterDialog">注册</span>
                  </div>
                </a-form-model-item>
              </a-form-model>
            </a-tab-pane>
            <a-tab-pane key="2" tab="短信登录" force-render>
              <a-form-model ref="phoneForm" :rules="rules" :model="formData">
                <a-form-model-item prop="mobile">
                  <a-input
                    v-model="formData.mobile"
                    :maxLength="11"
                    placeholder="请输入手机号"
                  >
                  </a-input>
                </a-form-model-item>
                <a-row>
                  <a-col class="gutter-row" :span="16">
                    <a-form-model-item prop="captcha">
                      <a-input
                        v-model="formData.captcha"
                        :maxLength="6"
                        type="text"
                        placeholder="请输入验证码"
                        class="code-input"
                      >
                      </a-input>
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="8">
                    <a-button
                      type="primary"
                      class="code-btn"
                      :disabled="state.smsSendBtn"
                      @click.stop.prevent="getCaptcha"
                      v-text="
                        (!state.smsSendBtn && '发送验证码') || state.time + ' s'
                      "
                    ></a-button>
                  </a-col>
                </a-row>

                <a-form-model-item>
                  <div class="forget" @click="openPassword">忘记密码？</div>
                </a-form-model-item>
                <a-form-model-item>
                  <a-button
                    type="primary"
                    class="login-btn"
                    @click="submitPhoneForm"
                  >
                    登录
                  </a-button>
                </a-form-model-item>
                <a-form-model-item>
                  <div class="reg-tips">
                    <span>没有账号？</span
                    ><span @click="openRegisterDialog">注册</span>
                  </div>
                </a-form-model-item>
              </a-form-model>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import reg from '@/api/regular'
import store from '@/store'
import { getAction, postAction } from '@/api/manage'
import { publicInfoIndex } from '@/api/api'
export default {
  data() {
    return {
      store,
      visible: false,
      formData: {
        username: '',
        password: '',
        inputCode: '',
        mobile: '',
        captcha: '',
      },
      rules: {
        username: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'change',
          },
        ],
        inputCode: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'change',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入登录密码',
            trigger: 'change',
          },
        ],
        mobile: [
          {
            required: true,
            message: '请输入手机号码',
            trigger: 'change',
          },
          {
            required: true,
            pattern: reg.phone,
            message: '请输入正确的手机号码',
            trigger: 'change',
          },
        ],
        captcha: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'change',
          },
          {
            required: true,
            pattern: reg.captcha,
            message: '请输入正确的验证码',
            trigger: 'change',
          },
        ],
      },
      currdatetime: '',
      randCodeImage: '',
      requestCodeSuccess: false,
      state: {
        time: 60,
        smsSendBtn: false,
      },
      code: '',
      tabIndex: 1,
      showCaptcha: 0,
    }
  },
  created() {
    this.getInfo()
    this.handleChangeCheckCode()
  },
  methods: {
    async getInfo() {
      try {
        const res = await publicInfoIndex()
        if (res.code == 200) {
          this.setParkInfo(res)
        } else {
          this.$message.error(res.message)
        }
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    setParkInfo(res) {
      let sysParameterList = res.result.sysParameterList
      let showCaptcha = sysParameterList.find((item) => {
        return item.code == 'is_shield_captcha'
      })
      this.showCaptcha = showCaptcha.value
      console.log(this.showCaptcha)
    },
    showModal() {
      this.visible = true
    },
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13) {
        if (this.tabIndex === 1) {
          this.submitForm()
        } else {
          this.submitPhoneForm()
        }
      }
    },
    submitForm() {
      const that = this
      that.$refs.accountNumberForm.validateField(
        ['username', 'password', 'inputCode'],
        (errMsg) => {
          if (errMsg === '') {
            const params = {
              username: that.formData.username,
              password: that.formData.password,
              captcha: that.formData.inputCode,
              checkKey: that.currdatetime,
            }
            that.store
              .dispatch('Login', params)
              .then((res) => {
                if (res.code == 200) {
                  that.visible = false
                  that.getAuth(res.result.userInfo.id)
                  that.getRole(res.result.userInfo.id)
                }
              })
              .catch((err) => {
                that.$message.error(err.message)
                // this.handleChangeCheckCode()
              })
          }
        }
      )
    },
    getAuth(id) {
      this.store
        .dispatch('GetUserAuthStatus', id)
        .then((res) => {})
        .catch((err) => {})
    },
    getRole(id) {
      this.store
        .dispatch('GetRole', id)
        .then((res) => {})
        .catch((err) => {})
    },
    handleChangeCheckCode() {
      this.currdatetime = new Date().getTime()
      getAction(`/sys/randomImage/${this.currdatetime}`)
        .then((res) => {
          if (res.success) {
            this.randCodeImage = res.result
            this.requestCodeSuccess = true
          } else {
            this.$message.error(res.message)
            this.requestCodeSuccess = false
          }
        })
        .catch(() => {
          this.requestCodeSuccess = false
        })
    },
    // 获取验证码
    getCaptcha() {
      let that = this
      this.$refs.phoneForm.validateField(['mobile'], (errMsg) => {
        if (errMsg === '') {
          this.state.smsSendBtn = true
          let interval = window.setInterval(() => {
            if (that.state.time-- <= 0) {
              that.state.time = 60
              that.state.smsSendBtn = false
              window.clearInterval(interval)
            }
          }, 1000)
          const params = {
            mobile: this.formData.mobile,
            smsmode: '2',
          }
          postAction('/sys/sms', params)
            .then((res) => {
              if (!res.success) {
                this.$message.error(res.message)
              }
            })
            .catch((err) => {
              clearInterval(interval)
              that.state.time = 60
              that.state.smsSendBtn = false
              this.requestFailed(err)
            })
        }
      })
    },
    requestFailed(err) {
      this.$message.error(
        ((err.response || {}).data || {}).message || '请求出现错误，请稍后再试'
      )
    },
    submitPhoneForm() {
      const that = this
      that.$refs.phoneForm.validateField(['mobile', 'captcha'], (errMsg) => {
        if (errMsg === '') {
          const params = {
            mobile: that.formData.mobile,
            captcha: that.formData.captcha,
          }
          that.store
            .dispatch('PhoneLogin', params)
            .then((res) => {
              that.visible = false
              that.getAuth(res.result.userInfo.id)
              that.getRole(res.result.userInfo.id)
            })
            .catch((err) => {
              this.$message.error(err.message)
            })
        }
      })
    },
    openRegisterDialog() {
      this.visible = false
      this.$register()
    },
    openPassword() {
      this.visible = false
      this.$password()
    },
    changeTabs(activeKey) {
      this.tabIndex = activeKey
      this.$refs.accountNumberForm.clearValidate()
      this.$refs.phoneForm.clearValidate()
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = ''
      })
    },
  },
  mounted() {
    // 绑定监听事件
    window.addEventListener('keydown', this.keyDown)
  },
  destroyed() {
    // 销毁事件
    window.removeEventListener('keydown', this.keyDown, false)
  },
}
</script>
<style lang="less" scoped>
/deep/ .login-style {
  border-radius: 30px;

  .ant-modal-content {
    border-radius: 10px;

    .title {
      margin-bottom: 30px;
      > div {
        font-size: 30px;
        font-weight: bold;
        color: #333333;
        margin-left: 20px;
      }
    }
  }

  .content {
    .forget {
      font-size: 14px;
      color: #1285d6;
      text-align: right;
      cursor: pointer;
    }

    .login-btn {
      width: 100%;
      height: 40px;
      font-size: 16px;
    }

    .reg-tips {
      text-align: center;
      span {
        font-size: 14px;
        color: #333333;
        &:nth-child(2) {
          color: #1285d6;
          cursor: pointer;
        }
      }
    }

    .ant-form {
      .ant-form-item {
        margin-bottom: 15px;
      }
      .ant-input {
        height: 40px;
        border-radius: 5px;
      }

      .code-input {
        border-right: none;
        border-radius: 5px 0 0 5px;
      }

      .gutter-row {
        .ant-form-item {
          margin: 0;
        }
      }
    }

    .input-code {
      .code-img {
        height: 40px;
        border-radius: 0 5px 5px 0;
        border: 1px solid #d9d9d9;
        cursor: pointer;

        img {
          width: 99%;
        }
      }
    }
  }

  .ant-tabs-bar {
    border: none;
    margin-bottom: 30px;
    .ant-tabs-nav {
      .ant-tabs-tab {
        color: #666666;
      }
      .ant-tabs-tab-active {
        color: #333333;
      }
    }
  }

  .code-btn {
    border: none;
    width: 100%;
    height: 40px;
    border-radius: 0 5px 5px 0;
    &[disabled] {
      background: #40a9ff;
      color: #fff;
    }
  }
}
</style>
