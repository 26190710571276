<template>
  <div>
    <a-modal
      :width="800"
      v-model="visible"
      centered
      :okButtonProps="{ style: { display: 'none' } }"
      cancelText="关闭"
    >
      <div class="content" v-html="infoData.richText"></div>
    </a-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      infoData: {},
    }
  },
  methods: {
    showModal(item) {
      this.visible = true
      this.infoData = item
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  padding: 30px;
  font-size: 16px;
  color: #333;
  line-height: 2;
}
</style>
