import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { VueAxios } from './axios'
import { Modal, notification } from 'ant-design-vue'
import { ACCESS_TOKEN, TENANT_ID, WEI_XIN_CODE, USER_INFO, ROLE_INFO } from '@/store/mutation-types'
import router from '@/router'

/**
 * 【指定 axios的 baseURL】
 * 如果手工指定 baseURL: '/jeecg-boot'
 * 则映射后端域名，通过 vue.config.js
 * @type {*|string}
 */

const apiBaseUrl = window._CONFIG['domianURL']
// 创建 axios 实例
const service = axios.create({
  // baseURL: '/jeecg-boot',
  baseURL: apiBaseUrl, // api base_url
  timeout: 30000, // 请求超时时间
})

const err = (error) => {
  if (error.response) {
    const data = error.response.data
    const token = Vue.ls.get(ACCESS_TOKEN)
    // console.log('------异常响应------', token)
    // console.log('------异常响应------', error.response.status)
    switch (error.response.status) {
      case 403:
        notification.error({
          message: '系统提示',
          description: '拒绝访问',
          duration: 4,
        })
        break
      case 500:
        // notification.error({ message: '系统提示', description:'Token失效，请重新登录!',duration: 4})
        if (token && data.message === 'Token失效，请重新登录') {
          // update-begin- --- author:scott ------ date:20190225 ---- for:Token失效采用弹框模式，不直接跳转----
          Vue.ls.remove(ACCESS_TOKEN)
          Vue.ls.remove(USER_INFO)
          Vue.ls.remove(ROLE_INFO)
          router.push({ path: '/' })
          window.location.reload()
        }
        break
      case 404:
        notification.error({
          message: '系统提示',
          description: '很抱歉，资源未找到!',
          duration: 4,
        })
        break
      case 504:
        notification.error({ message: '系统提示', description: '网络超时' })
        break
      case 401:
        notification.error({
          message: '系统提示',
          description: '未授权，请重新登录',
          duration: 4,
        })
        if (token) {
          store.dispatch('Logout').then(() => {
            setTimeout(() => {
              window.location.reload()
            }, 1500)
          })
        }
        break
      default:
        notification.error({
          message: '系统提示',
          description: data.message,
          duration: 4,
        })
        break
    }
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(
  (config) => {
    const token = Vue.ls.get(ACCESS_TOKEN)
    if (token) {
      config.headers['X-Access-Token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
    }
    // 拼接微信code
    const code = Vue.ls.get(WEI_XIN_CODE)
    if (code) {
      if (config.params) {
        config.params.code = code
      } else {
        config.params = {
          code: code,
        }
      }
    }
    // update-begin-author:taoyan date:2020707 for:多租户
    let tenantid = Vue.ls.get(TENANT_ID)
    if (!tenantid) {
      tenantid = 0
    }
    config.headers['tenant_id'] = tenantid
    // update-end-author:taoyan date:2020707 for:多租户
    if (config.method === 'get') {
      if (config.url.indexOf('sys/dict/getDictItems') < 0) {
        config.params = {
          _t: Date.parse(new Date()) / 1000,
          ...config.params,
        }
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use((response) => {
  return response.data
}, err)

const installer = {
  vm: {},
  install(Vue, router = {}) {
    Vue.use(VueAxios, router, service)
  },
}

export { installer as VueAxios, service as axios }
