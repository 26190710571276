<template>
  <div>
    <a-modal
      v-model="visible"
      :footer="null"
      centered
      dialogClass="login-style"
      :maskClosable="false"
      :width="400"
    >
      <div>
        <div class="title flex-wrap flex-h-center">
          <img src="@/assets/logo.png" alt="" width="80" height="80" />
          <div>修改密码</div>
        </div>
        <div class="content">
          <a-form-model
            layout="vertical"
            hideRequiredMark
            ref="formData"
            :rules="rules"
            :model="formData"
          >
            <a-form-model-item label="旧密码" prop="oldpassword">
              <a-input-password
                v-model="formData.oldpassword"
                autocomplete="new-password"
                placeholder="请输入旧密码"
                :maxLength="18"
              />
            </a-form-model-item>

            <a-form-model-item label="新密码" prop="password">
              <a-input-password
                v-model="formData.password"
                autocomplete="new-password"
                placeholder="请输入新密码"
                :maxLength="18"
              />
            </a-form-model-item>

            <a-form-model-item label="确认新密码" prop="confirmpassword">
              <a-input-password
                v-model="formData.confirmpassword"
                autocomplete="new-password"
                placeholder="请再次输入新密码"
                :maxLength="18"
              />
            </a-form-model-item>

            <a-form-model-item>
              <a-button type="primary" class="register-btn" @click="submitForm">
                确定
              </a-button>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import reg from '@/api/regular'
import { updatePassword } from '@/api/api'
export default {
  data() {
    let validatePass2 = (rule, value, callback) => {
      if (value !== this.formData.password) {
        callback(new Error('两次输入的密码不一样，请重新输入'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      formData: {
        username: '',
        oldpassword: '',
        password: '',
        confirmpassword: '',
      },
      rules: {
        oldpassword: [
          {
            required: true,
            message: '请输入旧密码',
            trigger: 'change',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入新密码',
            trigger: 'change',
          },
          {
            required: true,
            pattern: reg.password,
            message: '密码必须包含数字和字母，且在6～18位之间',
            trigger: 'change',
          },
        ],
        confirmpassword: [
          {
            required: true,
            message: '请再次输入新密码',
            trigger: 'change',
          },
          { validator: validatePass2, trigger: 'change' },
        ],
      },
    }
  },
  methods: {
    showModal(username) {
      this.formData.username = username
      this.visible = true
    },
    // 修改密码
    submitForm() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          const params = {
            ...this.formData,
          }
          updatePassword(params).then((res) => {
            if (res.success) {
              this.$message.success(res.message)
              this.close()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    close() {
      this.visible = false
      this.formData = {}
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .login-style {
  border-radius: 30px;

  .ant-modal-content {
    border-radius: 10px;

    .title {
      margin-bottom: 30px;
      > div {
        font-size: 30px;
        font-weight: bold;
        color: #333333;
        margin-left: 20px;
      }
    }
  }

  .content {
    .forget {
      font-size: 14px;
      color: #1285d6;
      text-align: right;
    }

    .register-btn {
      width: 100%;
      height: 40px;
      font-size: 16px;
      margin-top: 30px;
    }

    .reg-tips {
      text-align: center;
      span {
        font-size: 14px;
        color: #333333;
        &:nth-child(2) {
          color: #1285d6;
          cursor: pointer;
        }
      }
    }

    .ant-form {
      .ant-form-item {
        margin-bottom: 10px;
      }
      .ant-input {
        height: 40px;
        border-radius: 5px;
      }

      .code-input {
        border-right: none;
        border-radius: 5px 0 0 5px;
      }

      .gutter-row {
        .ant-form-item {
          margin-bottom: 5px;
        }
      }
    }
  }

  .code-btn {
    border: none;
    width: 100%;
    height: 40px;
    border-radius: 0 5px 5px 0;
    margin-top: 29px;

    &[disabled] {
      background: #40a9ff;
      color: #fff;
    }
  }
}
</style>
