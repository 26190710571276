import VueAMap from 'vue-amap'
VueAMap.initAMapApiLoader({
  key: '1eb9e476ae14105f35aef818dd9a25df',
  plugin: [
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.Autocomplete',
    'AMap.Geocoder',
    'AMap.Marker',
    'AMap.Polyline',
  ],
  v: '1.4.4',
})
export default VueAMap
