export const reg = {
  cardNum:
    /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, //身份证
  phone:
    /^(13[0-9]|14[56789]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[1589])\d{8}$/, //手机
  charterNum:
    /(^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$)|(^\d{15}$)/, //营业执照
  decimal: /^\d+(\.\d{0,2})?$/, //小数保留两位
  carNum:
    /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/,
  carVin: /[\dA-HJ-NPR-Z]{17}/,
  contractMun: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/, //数字字母组合
  integer: /^[1-9]\d*$/, //正整数(不包括0)
  numberLetters: /^[A-Za-z0-9]+$/, //数字和字母
  captcha: /^\d{6}$/, //6位数字验证码
  email: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, //邮箱
  password: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z\\W]{6,18}$/, //密码
  accountNumber: /^[A-Za-z0-9]{6,18}$/, //账号
}
export default reg
