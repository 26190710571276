<template>
  <div>
    <a-modal
      :width="800"
      v-model="visible"
      centered
      :okButtonProps="{ style: { display: 'none' } }"
      cancelText="关闭"
    >
      <div
        v-html="setParkBasicInfo(sysParameterList, 'register_agreement')"
      ></div>
    </a-modal>
  </div>
</template>
<script>
import { publicInfoIndex } from '@/api/api'
import { setParkBasicInfo } from '@/api/common'
export default {
  data() {
    return {
      visible: false,
      sysParameterList: [],
      parkInfo: {},
      setParkBasicInfo,
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    async getInfo() {
      try {
        const res = await publicInfoIndex()
        if (res.code == 200) {
          this.sysParameterList = res.result.sysParameterList
        } else {
          this.$message.error(res.message)
        }
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    showModal() {
      this.visible = true
    },
  },
}
</script>
