import './config'
import Vue from 'vue'
import App from './App.vue'
import Storage from 'vue-ls'
import router from './router'
import { VueAxios } from '@/utils/request'
import './permission'

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
Vue.use(Antd)

//注入全局属性$message
import { message } from 'ant-design-vue'
Vue.prototype.$message = message
message.config({
  duration: 3, // 持续时间
  top: '90%', // 到页面顶部距离
  maxCount: 1, // 最大显示数, 超过限制时，最早的消息会被自动关闭
})

import login from './components/Login/index' // 登录弹窗全局组件
Vue.prototype.$login = login.install // 全局可使用this.$login调用

import register from './components/Register/index' // 注册弹窗全局组件
Vue.prototype.$register = register.install

import password from './components/ForgetPassword/index' // 忘记密码弹窗全局组件
Vue.prototype.$password = password.install

import auth from './components/Modal/index' // 认证提示全局组件
Vue.prototype.$auth = auth.install

import changePassword from './components/ChangePassword/index' // 修改密码全局组件
Vue.prototype.$changePassword = changePassword.install

Vue.use(Storage)
Vue.use(VueAxios, router)

import VueAMap from './utils/amap'
Vue.use(VueAMap)

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
Vue.use(VueViewer)

import currentDate from '@/utils/currentDate'
Vue.prototype.$currentDate= currentDate

import store from './store'

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
