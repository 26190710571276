import Vue from 'vue'
import VueRouter from 'vue-router'

import { BasicLayout, BlankLayout, NewsView } from '@/layouts'

Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const routes = [
  {
    path: '/',
    component: BasicLayout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: BlankLayout,
        redirect: '/home/index',
        children: [
          {
            path: '/home/index',
            name: 'HomeIndex',
            component: () =>
              import(/* webpackChunkName: "home" */ '@/views/Home/Index'),
            meta: {
              keepAlive: true, // 是否被缓存
              showRota: true, //是否显示轮播图
            },
          },
          {
            path: '/home/article_details/:id/:type',
            name: 'ArticleDetails',
            component: () =>
              import(
                /* webpackChunkName: "home" */ '@/views/Home/ArticleDetails'
              ),
            meta: {
              keepAlive: false, // 是否被缓存
              showRota: false, //是否显示轮播图
            },
          },
          {
            path: '/home/businessDetails/:data',
            name: 'BusinessDetails',
            component: () =>
              import(
                /* webpackChunkName: "home" */ '@/views/Home/BusinessDetails'
              ),
            meta: {
              keepAlive: false, // 是否被缓存
              showRota: false, //是否显示轮播图
            },
          },
          {
            path: '/home/PositionDetails/:data',
            name: 'PositionDetails',
            component: () =>
              import(
                /* webpackChunkName: "home" */ '@/views/Home/PositionDetails'
              ),
            meta: {
              keepAlive: false, // 是否被缓存
              showRota: false, //是否显示轮播图
            },
          },
        ],
      },
      {
        path: '/policy',
        name: 'Policy',
        component: BlankLayout,
        redirect: '/policy/index',
        children: [
          {
            path: '/policy/index',
            name: 'PolicyIndex',
            component: () =>
              import(/* webpackChunkName: "policy" */ '@/views/Policy/Index'),
            meta: {
              keepAlive: true, // 是否被缓存
              showRota: true, //是否显示轮播图
            },
          },
          {
            path: '/policy/activity/activity_details/:id',
            name: 'ActivityDetails',
            component: () =>
              import(
                /* webpackChunkName: "policy" */ '@/views/Policy/Activity/ActivityDetails'
              ),
            meta: {
              keepAlive: false, // 是否被缓存
              showRota: false, //是否显示轮播图
            },
          },
          {
            path: '/policy/news/news_details/:data',
            name: 'NewsDetails',
            component: () =>
              import(
                /* webpackChunkName: "policy" */ '@/views/Policy/News/NewsDetails'
              ),
            meta: {
              keepAlive: false, // 是否被缓存
              showRota: false, //是否显示轮播图
            },
          },
        ],
      },
      {
        path: '/resources',
        name: 'Resources',
        component: BlankLayout,
        redirect: '/resources/index',
        children: [
          {
            path: '/resources/index',
            name: 'ResourcesIndex',
            component: () =>
              import(
                /* webpackChunkName: "resources" */ '@/views/Resources/Index'
              ),
            meta: {
              keepAlive: true, // 是否被缓存
              showRota: true, //是否显示轮播图
            },
          },
          {
            path: '/resources/mechanism_details/:data',
            name: 'MechanismDetails',
            component: () =>
              import(
                /* webpackChunkName: "resources" */ '@/views/Resources/Mechanism/MechanismDetails'
              ),
            meta: {
              keepAlive: false, // 是否被缓存
              showRota: false, //是否显示轮播图
            },
          },
          {
            path: '/resources/enterprise_details/:data',
            name: 'EnterpriseDetails',
            component: () =>
              import(
                /* webpackChunkName: "resources" */ '@/views/Resources/Enterprise/EnterpriseDetails'
              ),
            meta: {
              keepAlive: true, // 是否被缓存
              showRota: false, //是否显示轮播图
            },
          },
          {
            path: '/resources/PositionDetails/:data',
            name: 'EnterprisePositionDetails',
            component: () =>
              import(
                /* webpackChunkName: "resources" */ '@/views/Resources/Enterprise/PositionDetails'
              ),
            meta: {
              keepAlive: true, // 是否被缓存
              showRota: false, //是否显示轮播图
            },
          },
        ],
      },
      {
        path: '/service',
        name: 'Service',
        component: BlankLayout,
        redirect: '/service/index',
        children: [
          {
            path: '/service/index',
            name: 'ServiceIndex',
            component: () =>
              import(/* webpackChunkName: "service" */ '@/views/Service/Index'),
            meta: {
              keepAlive: true, // 是否被缓存
              showRota: true, //是否显示轮播图
            },
          },
          {
            path: '/service/apply_service/:data',
            name: 'ApplyService',
            component: () =>
              import(
                /* webpackChunkName: "service" */ '@/views/Service/ApplyService'
              ),
            meta: {
              keepAlive: false, // 是否被缓存
              showRota: false, //是否显示轮播图
            },
          },
          {
            path: '/service/conference_room/:data',
            name: 'ConferenceRoom',
            component: () =>
              import(
                /* webpackChunkName: "service" */ '@/views/Service/ConferenceRoom'
              ),
            meta: {
              keepAlive: false, // 是否被缓存
              showRota: false, //是否显示轮播图
            },
          },
        ],
      },
      {
        path: '/recruit',
        name: 'Recruit',
        component: BlankLayout,
        redirect: '/recruit/index',
        children: [
          {
            path: '/recruit/index',
            name: 'RecruitIndex',
            component: () =>
              import(/* webpackChunkName: "recruit" */ '@/views/Recruit/Index'),
            meta: {
              keepAlive: true, // 是否被缓存
              showRota: true, //是否显示轮播图
            },
          },
          {
            path: '/recruit/recruit_details/:data',
            name: 'RecruitDetails',
            component: () =>
              import(
                /* webpackChunkName: "recruit" */ '@/views/Recruit/RecruitDetails'
              ),
            meta: {
              keepAlive: false, // 是否被缓存
              showRota: false, //是否显示轮播图
            },
          },
        ],
      },
      {
        path: '/about',
        name: 'About',
        component: () =>
          import(/* webpackChunkName: "about" */ '@/views/About/Index'),
        meta: {
          keepAlive: true, // 是否被缓存
          showRota: true, //是否显示轮播图
        },
      },
      {
        path: '/personalCenter',
        name: 'PersonalCenter',
        component: BlankLayout,
        redirect: '/personal_center/index',
        children: [
          {
            path: '/personal_center/index',
            name: 'PersonalCenterIndex',
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/PersonalCenter/Index'
              ),
            meta: {
              keepAlive: true, // 是否被缓存
              showRota: false, //是否显示轮播图
            },
          },
          {
            path: '/personal_center/activity/activity_details',
            name: 'PersonalCenterActivityDetails',
            component: () =>
              import(
                /* webpackChunkName: "personal" */ '@/views/PersonalCenter/Activity/ActivityDetails'
              ),
            meta: {
              keepAlive: true, // 是否被缓存
              showRota: false, //是否显示轮播图
            },
          },
        ],
      },
      {
        path: '/authentication',
        name: 'Authentication',
        component: () =>
          import(/* webpackChunkName: "auth" */ '@/views/Authentication/Index'),
        meta: {
          keepAlive: false, // 是否被缓存
          showRota: false, //是否显示轮播图
        },
      },
      {
        path: '/search_info',
        name: 'SearchInfo',
        component: () =>
          import(
            /* webpackChunkName: "search" */ '@/views/SearchInfo/SearchInfo'
          ),
        meta: {
          keepAlive: true, // 是否被缓存
          showRota: false, //是否显示轮播图
        },
      },
    ],
  },
  {
    path: '/404',
    component: () =>
      import(/* webpackChunkName: "fail" */ '@/views/Exception/404'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/part/official/',
  routes,
})

export default router
