import Vue from 'vue'
import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { USER_INFO } from '@/store/mutation-types'
NProgress.configure({ showSpinner: false }) // NProgress Configuration

router.beforeEach((to, from, next) => {
  const userInfo = Vue.ls.get(USER_INFO)
  if (userInfo) {
    store.dispatch('GetUserAuthStatus', userInfo.id)
    store.dispatch('GetRole', userInfo.id)
  }
  NProgress.start() // start progress bar
  next()
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
