<template>
  <div style="min-width: 1200px">
    <Header />
    <keep-alive>
      <Rotation v-if="$route.meta.showRota" />
    </keep-alive>

    <keep-alive>
      <router-view v-if="$route.meta.keepAlive">
        <!-- 这里是会被缓存的视图组件，比如 Home！ -->
      </router-view>
    </keep-alive>

    <router-view v-if="!$route.meta.keepAlive">
      <!-- 这里是不会被缓存的视图组件，比如 Profile！ -->
    </router-view>

    <a-back-top :visibilityHeight="1200">
      <div class="ant-back-top-inner"></div>
    </a-back-top>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Rotation from '@/components/HomeRotation'

export default {
  name: 'BasicLayout',
  components: {
    Header,
    Footer,
    Rotation,
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style lang="less">
@import '~@/assets/styles/common.less';

.ant-back-top-inner {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 4px;
  background-color: #1088e9;
  color: #fff;
  text-align: center;
  font-size: 20px;
  background-image: url(~@/assets/up.png);
  background-repeat: no-repeat;
  background-position: center;
}
</style>
