import Vue from 'vue'
import { login, phoneLogin, logout, queryUserRoleInfo } from '@/api/login'
import { ACCESS_TOKEN, USER_INFO, ROLE_INFO } from '@/store/mutation-types'

const user = {
  state: {
    token: '',
    info: {},
    role: [],
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_ROLE(state, info) {
      state.role = info
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then((response) => {
            if (response.code == '200') {
              const result = response.result
              const userInfo = result.userInfo
              Vue.ls.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
              Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000)
              commit('SET_TOKEN', result.token)
              commit('SET_INFO', userInfo)
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 手机号登录
    PhoneLogin({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        phoneLogin(userInfo)
          .then((response) => {
            if (response.code == '200') {
              const result = response.result
              const userInfo = result.userInfo
              Vue.ls.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
              Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000)
              commit('SET_TOKEN', result.token)
              commit('SET_INFO', userInfo)
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取用户角色
    GetRole({ commit }, userId) {
      return new Promise((resolve, reject) => {
        queryUserRoleInfo(userId)
          .then((response) => {
            if (response.success) {
              const roleList = response.result
              // console.log('用户角色', roleList)
              Vue.ls.set(ROLE_INFO, roleList, 7 * 24 * 60 * 60 * 1000)
              commit('SET_ROLE', roleList)
              resolve(response)
            } else {
              const roleList = []
              Vue.ls.set(ROLE_INFO, roleList, 7 * 24 * 60 * 60 * 1000)
              commit('SET_ROLE', roleList)
              reject(response)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 登出
    Logout({ commit, state }) {
      return new Promise((resolve) => {
        const logoutToken = state.token
        commit('SET_TOKEN', '')
        commit('SET_INFO', {})
        commit('SET_ROLE', [])
        Vue.ls.remove(ACCESS_TOKEN)
        Vue.ls.remove(USER_INFO)
        Vue.ls.remove(ROLE_INFO)
        logout(logoutToken)
          .then(() => {
            resolve()
          })
          .catch(() => {
            resolve()
          })
      })
    },
    // 修改用户信息
    reviseUserInfo({ commit }, userInfo) {
      commit('SET_INFO', userInfo)
    },
  },
}

export default user
