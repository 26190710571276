import Vue from 'vue'
import password from './ForgetPassword'

const PasswordDialog = Vue.extend(password)

password.install = function (data) {
  let instance = new PasswordDialog({
    data,
  }).$mount()

  document.body.appendChild(instance.$el)

  Vue.nextTick(() => {
    instance.showModal()
  })
}

export default password // 导出
