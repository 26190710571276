import Vue from 'vue'
import register from './register' // 引入注册组件

const RegisterDialog = Vue.extend(register)

register.install = function (data) {
  let instance = new RegisterDialog({
    data,
  }).$mount()

  document.body.appendChild(instance.$el)

  Vue.nextTick(() => {
    instance.showModal()
  })
}

export default register // 导出
