import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import auth from './modules/auth'
import getters from './getters'
import persistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    auth,
  },
  state: {},
  mutations: {},
  actions: {},
  getters,
  plugins: [persistedState({ storage: window.sessionStorage })],
})
