<template>
  <div class="rota">
    <swiper :options="swiperOption" ref="rotationSwiper">
      <swiper-slide v-for="(item, index) in lunbotuList" :key="index">
        <div class="swiper-item" @click="openDetails(item)">
          <img :src="item.picAddress" alt="" width="100%" height="400" />
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <!-- <div class="swiper-btn button-prev"></div>
    <div class="swiper-btn button-next"></div> -->
    <rotation-modal ref="rotationModal" />
  </div>
</template>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import { publicInfoIndex } from '@/api/api'
import RotationModal from './RotationModal'

export default {
  name: 'Rotation',
  components: {
    swiper,
    swiperSlide,
    RotationModal,
  },
  data() {
    return {
      swiperOption: {
        init: false,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          bulletClass: 'my-bullet',
          bulletActiveClass: 'my-bullet-active',
        },
        allowTouchMove: false,
      },
      lunbotuList: [],
      getDataFlag: true,
    }
  },
  computed: {
    swiper() {
      return this.$refs.rotationSwiper.swiper
    },
  },
  updated() {
    //解决轮播图 动态加载数据 loop失效问题
    if (this.lunbotuList.length > 1) {
      this.swiper.init()
    }
  },
  created() {
    if (this.getDataFlag) {
      this.getInfo()
    }
  },
  methods: {
    async getInfo() {
      try {
        const res = await publicInfoIndex()
        if (res.code == 200) {
          this.lunbotuList = res.result.lunbotuList
          this.getDataFlag = false
        } else {
          this.$message.error(res.message)
        }
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    openDetails(item) {
      console.log(item)
      if (item.linkType === 1) {
        window.open(item.linkAddress, '_blank')
      } else if (item.linkType === 2) {
        this.$refs.rotationModal.showModal(item)
      } else if (item.linkType === 3) {
        this.$router.push({
          name: 'ArticleDetails',
          params: {
            id: item.infoId,
            type: 'info',
          },
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
.rota {
  width: 100%;
  height: 400px;
  position: relative;

  .swiper-btn {
    width: 64px;
    height: 64px;
    z-index: 10;
    position: absolute;
    top: 192px;
    cursor: pointer;
  }

  .button-prev {
    background-image: url(~@/assets/left.png);
    left: 0;
  }

  .button-next {
    background-image: url(~@/assets/right.png);
    right: 0;
  }

  /deep/ .swiper-pagination {
    .my-bullet {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      margin: 10px;
      cursor: pointer;
    }

    .my-bullet-active {
      background: #1285d6;
    }
  }

  .swiper-item {
    cursor: pointer;
  }
}
</style>
