<template>
  <div>
    <header>
      <div class="flex-wrap">
        <div class="header-left">
          <router-link to="/home/index">
            <div class="logo flex-wrap flex-h-center">
              <div>
                <img src="@/assets/logo.png" alt="" width="80" height="80" />
              </div>
              <div class="title">
                <h1>咸阳高新区创新创业公共服务平台</h1>
              </div>
            </div>
          </router-link>
        </div>
        <div class="header-right flex-con flex-wrap flex-between">
          <div
            class="search flex-wrap flex-h-center"
            @click="
              $router.push({
                name: 'SearchInfo',
              })
            "
          >
            <img src="@/assets/image/magnifier.png" alt="" />
            <span>请输入关键字全站搜索</span>
          </div>
          <div class="language-nav flex-con">
            <ul
              class="flex-wrap flex-h-center flex-justify-content-end"
              v-if="accessToken == ''"
            >
              <li @click="openLoginDialog">登录</li>
              <li class="line"></li>
              <li @click="openRegisterDialog">注册</li>
            </ul>
            <div class="" v-if="accessToken != ''">
              <a-dropdown overlayClassName="dropdown-menu">
                <div class="t-hide user-name">
                  <span
                    >欢迎您，{{
                      userInfo.realname ? userInfo.realname : userInfo.username
                    }}</span
                  >
                </div>
                <a-menu slot="overlay">
                  <a-menu-item
                    key="1"
                    @click="$router.push({ path: '/personalCenter' })"
                  >
                    个人中心
                  </a-menu-item>
                  <a-menu-item key="2" @click="openChangePassword">
                    修改密码
                  </a-menu-item>
                  <a-menu-item key="3" @click="signOut"> 退出登陆 </a-menu-item>
                </a-menu>
                <a-button style="margin-left: 8px">
                  Button <a-icon type="down" />
                </a-button>
              </a-dropdown>
            </div>
          </div>
        </div>
      </div>
    </header>

    <a-affix :offset-top="0">
      <div class="nav">
        <div class="flex-wrap flex-h-center flex-between">
          <nav>
            <router-link to="/home" active-class="actived">首页</router-link>
            <router-link to="/policy" active-class="actived"
              >平台资讯</router-link
            >
            <router-link to="/resources" active-class="actived"
              >平台资源</router-link
            >
            <router-link to="/service" active-class="actived"
              >平台服务</router-link
            >
            <router-link to="/recruit" active-class="actived"
              >平台招聘</router-link
            >
            <router-link to="/About" active-class="actived"
              >关于平台</router-link
            >
          </nav>
        </div>
      </div>
    </a-affix>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'Header',
  data() {
    return {
      headerFixed: '',
    }
  },
  computed: {
    ...mapState({
      accessToken: (state) => state.user.token,
      userInfo: (state) => state.user.info,
    }),
  },
  methods: {
    ...mapActions(['Logout']),
    openLoginDialog() {
      this.$login()
    },
    openRegisterDialog() {
      this.$register()
    },
    signOut() {
      this.Logout({})
        .then(() => {
          this.$router.push({ path: '/' })
          // window.location.reload()
        })
        .catch((err) => {
          this.$message.error({
            title: '错误',
            description: err.message,
          })
        })
    },
    openChangePassword() {
      const data = {
        username: this.userInfo.username,
      }
      this.$changePassword(data)
    },
  },
}
</script>
<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
  font-family: Source Han Sans CN;
}
header {
  height: 100px;
  > div {
    width: 1200px;
    margin: auto;
    .header-left {
      .logo {
        height: 100px;
        .title {
          margin-left: 36px;
          text-align: center;
          h1,
          h3 {
            color: #1285d6;
          }

          h1 {
            font-size: 34px;
          }

          h3 {
            font-size: 28px;
            margin-top: -15px;
          }
        }
      }
    }

    .header-right {
      .search {
        width: 300px;
        height: 50px;
        border: 1px solid #cccccc;
        border-radius: 27px;
        font-size: 16px;
        color: #999999;
        padding-left: 30px;
        cursor: pointer;
        margin-top: 25px;
        margin-left: 100px;
        span {
          margin-left: 10px;
        }
      }
      .language-nav {
        color: #666;
        margin-top: 10px;
        margin-left: 30px;

        li {
          cursor: pointer;

          &:nth-child(1) {
            overflow: hidden;
            text-align: right;
          }
        }

        .line {
          width: 1px;
          height: 15px;
          background: #666;
          margin: 0 10px;
        }
      }
    }
  }
}

.nav {
  height: 90px;
  background: #1285d6;
  > div {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    nav {
      a {
        display: inline-block;
        line-height: 90px;
        font-size: 18px;
        color: #fefefe;
        cursor: pointer;
        padding: 0 14px;
        margin-left: 39px;

        &:first-child {
          margin-left: 0;
        }

        &:hover,
        &.actived {
          background: #71bcf3;
          opacity: 0.71;
        }
      }
    }
  }
}

/deep/ .ant-affix {
  z-index: 99;
}

.user-name {
  cursor: pointer;
}
</style>
<style lang="less">
.dropdown-menu {
  ul {
    li {
      text-align: center;
    }
  }
}
</style>
