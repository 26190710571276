import Vue from 'vue'
import auth from './authPromptBox.vue'

const AuthDialog = Vue.extend(auth)

auth.install = function (iden, info) {
  let instance = new AuthDialog({
    iden,
    info,
  }).$mount()

  document.body.appendChild(instance.$el)

  Vue.nextTick(() => {
    instance.showModal(iden, info)
  })
}

export default auth // 导出
