<template>
  <div>
    <a-modal
      v-model="visible"
      :footer="null"
      centered
      dialogClass="login-style"
      :maskClosable="false"
      :closable="false"
      :width="400"
    >
      <div class="content">
        <h2 v-if="this.authMessage === 0">您没有该功能的权限，请前往认证</h2>
        <h2 v-else-if="this.authMessage === 100 || this.authMessage === 200">
          您的认证还在审核中，请稍等
        </h2>
        <h2 v-else-if="this.authMessage === 102 || this.authMessage === 202">
          您的认证审核被驳回，请重新认证
        </h2>
        <h2 v-else>您没有该功能的权限，请联系客服</h2>
        <div class="btn-item">
          <a-button
            class="btn"
            type="primary"
            @click="determine"
            v-if="this.authMessage === 0"
          >
            去认证
          </a-button>

          <a-button
            class="btn"
            type="primary"
            @click="recertification"
            v-if="this.authMessage === 102 || this.authMessage === 202"
          >
            去认证
          </a-button>

          <a-button class="btn" @click="close">取消</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import router from '@/router'
export default {
  data() {
    return {
      router,
      visible: false,
      authMessage: undefined,
    }
  },
  methods: {
    showModal(authMessage) {
      this.authMessage = authMessage
      this.visible = true
    },
    close() {
      this.visible = false
    },
    determine() {
      this.router.push({ path: '/authentication' })
      this.close()
    },
    recertification() {
      this.router.push({ path: '/PersonalCenter' })
      this.close()
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  text-align: center;

  .btn-item {
    padding-top: 20px;

    .btn {
      margin: 0 15px;
    }
  }
}
</style>
