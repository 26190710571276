<template>
  <div>
    <a-modal
      v-model="visible"
      :footer="null"
      centered
      dialogClass="login-style"
      :maskClosable="false"
      :width="400"
    >
      <div>
        <div class="title flex-wrap flex-h-center">
          <img src="@/assets/logo.png" alt="" width="80" height="80" />
          <div>忘记密码</div>
        </div>
        <div class="content">
          <a-form-model
            layout="vertical"
            hideRequiredMark
            ref="formData"
            :rules="rules"
            :model="formData"
          >
            <a-form-model-item label="手机号" prop="phone">
              <a-input
                v-model="formData.phone"
                placeholder="请输入手机号"
                :maxLength="11"
              >
              </a-input>
            </a-form-model-item>

            <a-row>
              <a-col class="gutter-row" :span="16">
                <a-form-model-item label="验证码" prop="smscode">
                  <a-input
                    v-model="formData.smscode"
                    type="text"
                    placeholder="请输入验证码"
                    class="code-input"
                    :maxLength="6"
                    autocomplete="off"
                  >
                  </a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-button
                  type="primary"
                  class="code-btn"
                  :disabled="state.smsSendBtn"
                  @click.stop.prevent="getCaptcha"
                  v-text="
                    (!state.smsSendBtn && '获取验证码') || state.time + ' s'
                  "
                ></a-button>
              </a-col>
            </a-row>

            <a-form-model-item label="密码" prop="password">
              <a-input-password
                v-model="formData.password"
                autocomplete="new-password"
                placeholder="请设置登录密码"
                :maxLength="18"
              />
            </a-form-model-item>

            <a-form-model-item>
              <a-button type="primary" class="register-btn" @click="submitForm">
                确定
              </a-button>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import reg from '@/api/regular'
import { postAction, getAction } from '@/api/manage'
export default {
  data() {
    return {
      visible: false,
      formData: {
        password: '',
        phone: '',
        smscode: '',
      },
      rules: {
        phone: [
          {
            required: true,
            message: '请输入手机号码',
            trigger: 'change',
          },
          {
            required: true,
            pattern: reg.phone,
            message: '请输入正确的手机号码',
            trigger: 'change',
          },
        ],
        smscode: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'change',
          },
          {
            required: true,
            pattern: reg.captcha,
            message: '请输入正确的验证码',
            trigger: 'change',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入登录密码',
            trigger: 'change',
          },
          {
            required: true,
            pattern: reg.password,
            message: '密码必须包含数字和字母，且在6～18位之间',
            trigger: 'change',
          },
        ],
      },
      state: {
        time: 60,
        smsSendBtn: false,
      },
    }
  },
  methods: {
    showModal() {
      this.visible = true
    },
    // 打开登录弹框
    openLoginDialog() {
      this.visible = false
      this.$login()
    },
    // 忘记密码
    submitForm() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          const params = {
            password: this.formData.password,
            phone: this.formData.phone,
            smscode: this.formData.smscode,
          }
          getAction('/sys/user/passwordReset', params).then((res) => {
            if (res.success) {
              this.$message.success(res.message)
              this.openLoginDialog()
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          return false
        }
      })
    },
    // 获取验证码
    getCaptcha() {
      let that = this
      this.$refs.formData.validateField(['phone'], (errMsg) => {
        if (errMsg === '') {
          this.state.smsSendBtn = true
          let interval = window.setInterval(() => {
            if (that.state.time-- <= 0) {
              that.state.time = 60
              that.state.smsSendBtn = false
              window.clearInterval(interval)
            }
          }, 1000)
          const params = {
            mobile: this.formData.phone,
            smsmode: '2',
          }
          postAction('/sys/sms', params)
            .then((res) => {
              if (res.success) {
                this.$message.success('验证码已发送，请注意查收')
              } else {
                this.$message.error(res.message)
              }
            })
            .catch((err) => {
              clearInterval(interval)
              that.state.time = 60
              that.state.smsSendBtn = false
              this.requestFailed(err)
            })
        }
      })
    },

    requestFailed(err) {
      this.$message.error(
        ((err.response || {}).data || {}).message || '请求出现错误，请稍后再试'
      )
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .login-style {
  border-radius: 30px;

  .ant-modal-content {
    border-radius: 10px;

    .title {
      margin-bottom: 30px;
      > div {
        font-size: 30px;
        font-weight: bold;
        color: #333333;
        margin-left: 20px;
      }
    }
  }

  .content {
    .forget {
      font-size: 14px;
      color: #1285d6;
      text-align: right;
    }

    .register-btn {
      width: 100%;
      height: 40px;
      font-size: 16px;
      margin-top: 30px;
    }

    .reg-tips {
      text-align: center;
      span {
        font-size: 14px;
        color: #333333;
        &:nth-child(2) {
          color: #1285d6;
          cursor: pointer;
        }
      }
    }

    .ant-form {
      .ant-form-item {
        margin-bottom: 10px;
      }
      .ant-input {
        height: 40px;
        border-radius: 5px;
      }

      .code-input {
        border-right: none;
        border-radius: 5px 0 0 5px;
      }

      .gutter-row {
        .ant-form-item {
          margin-bottom: 5px;
        }
      }
    }
  }

  .code-btn {
    border: none;
    width: 100%;
    height: 40px;
    border-radius: 0 5px 5px 0;
    margin-top: 29px;

    &[disabled] {
      background: #40a9ff;
      color: #fff;
    }
  }
}
</style>
